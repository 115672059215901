import { createRouter, createWebHistory } from '@ionic/vue-router';
// import Tabs from '../views/Tabs.vue'
// import Tab1 from '../views/Tab1.vue'
// import Tab2 from '../views/Tab2.vue'
// import Tab3 from '../views/Tab3.vue'
// import Tab4 from '../views/Tab4.vue'
// import Tab5 from '../views/Tab5.vue'

// import ListItemModalEvents from "../components/ListItemModalEvents.vue" // Removed this initial import to test if it would make app launch faster
// import ModalEvents from "../components/ModalEvents.vue" // Removed this initial import to test if it would make app launch faster
// import NewItemModalEvents from "../components/NewItemModalEvents.vue" // Removed this initial import to test if it would make app launch faster
// import ListRenameModalEvents from "../components/ListRenameModalEvents.vue" // Removed this initial import to test if it would make app launch faster
// import NotFound from "../components/NotFound.vue" // Removed this initial import to test if it would make app launch faster

const routes = [
  {
    path: '/',
    redirect: '/loading'
  },
  {
    path: '/loading',
    name: 'Splashscreen',
    component: () => import('@/views/Splashscreen.vue'),
    // props: true,
  },
  {
    path: '/tabs/',
    // component: Tabs,
    component: () => import('@/views/Tabs.vue'),
    children: [
      {
        path: '',
        redirect: 'tab4',
      },
      {
        path: 'tab4',
        // component: Tab4,
        component: () => import('@/views/Tab4.vue'),
        name: 'Tab4',
      },
      {
        // path: 'tab4/listitems',
        path: '/listitems-1',
        name: 'ListItems',
        component: () => import('@/components/ListItemModalEvents.vue'),
        // component: ListItemModalEvents,
        props: true,
        // props: (route) => ({ listId: route.params.listId }),
        // props: (route) => ({ listId: JSON.stringify(route.params.listId) }),
        // props: {
        //   listId: {
        //     type: Object,
        //     default: function () {
        //       // return { message: 'hello' }
        //       return ""
        //     },
        //   },
        // },
      },
      {
        // path: 'tab4/listitems/item',
        path: '/item-1',
        name: 'ModalEvents',
        component: () => import('@/components/ModalEvents.vue'),
        // component: ModalEvents,
        props: true,
      },
      {
        // path: 'tab4/newitem',
        path: '/newitem',
        name: 'NewItemModalEvents',
        component: () => import('@/components/NewItemModalEvents.vue'),
        // component: NewItemModalEvents,
        props: true,
      },
      {
        // path: 'tab4/rename',
        path: '/rename',
        name: 'ListRenameModalEvents',
        component: () => import('@/components/ListRenameModalEvents.vue'),
        // component: ListRenameModalEvents,
        props: true,
      },
      {
        path: '/share',
        name: 'ListShare',
        component: () => import('@/components/ListShare.vue'),
        props: true,
      },
      {
        path: '/recycle',
        name: 'RecycleBin',
        component: () => import('@/components/RecycleModal.vue'),
        // props: true,
      },
      {
        path: '/account',
        name: 'UserAccount',
        component: () => import('@/components/UserAccount.vue'),
        // props: true,
      },
      {
        path: '/files',
        name: 'FileView',
        component: () => import('@/views/FolderView.vue'),
        // props: true,
      },
      {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('@/views/Contacts.vue'),
        // props: true,
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1.vue')
        // component: Tab1,
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2.vue')
        // component: Tab2,
      },
      {
        // path: 'tab2/item',
        path: '/item-2',
        name: 'ModalEventsTab2',
        // component: ModalEvents,
        component: () => import('@/components/ModalEvents.vue'),
        props: true,
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3.vue')
      },
      {
        // path: 'tab3/item',
        path: 'item-3',
        name: 'ModalEventsTab3',
        // component: ModalEvents,
        component: () => import('@/components/ModalEvents.vue'),
        props: true,
      },
      {
        // path: 'tab3/listitems',
        path: '/listitems-2',
        name: 'ListItemsTab3',
        component: () => import('@/components/ListItemModalEvents.vue'),
        // component: ListItemModalEvents,
        props: true,
      },
      {
        path: 'tab5',
        component: () => import('@/views/Tab5.vue')
        // component: Tab5,
      },
      {
        path: 'item-4',
        name: 'ModalEventsTab5',
        component: () => import('@/components/ModalEvents.vue'),
        // component: ModalEvents,
        props: true,
      },

    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/components/NotFound.vue'),
    // component: NotFound
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
