import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './registerServiceWorker'
import store from './store'

import { IonicVue } from '@ionic/vue';

// *** NEW: Deploy to Google Firebase Hosting ***
  // 1. run in console: 'npm run build'
  // 2. run in console: 'firebase deploy'

      // a. run 'firebase init' if need to make chaneges to Firebase Hosting configuration
      // b. available at these domains:
        // aa. https://appsmith.app/
        // bb. https://www.appsmith.app/
        // cc. https://cla-ionic-vue.web.app/
        // dd. https://cla-ionic-vue.firebaseapp.com/

// *** Committing to Github ***
  // 1. git add .
  // 2. git commit -m "Notes here..."
  // 3. git push origin master


// *** Localhost server with Ionic ***
  // 1. ionic serve

// OLD - Deploys to Netlify, I changed the original Netlify build command from 'npm run build' to 'ionic build --prod --aot --minifyjs --minifycss --optimizejs':
// OLD - git add .
// OLD - git commit -m "Notes here..."
// OLD - git push origin master


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

// https://remixicon.com/
// https://github.com/Remix-Design/remixicon#usage
import 'remixicon/fonts/remixicon.css';

// import ReactiveProvide from 'vue-reactive-provide'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';


// import '@syncfusion/ej2-base/styles/material.css';  
// import '@syncfusion/ej2-buttons/styles/material.css';  
// import '@syncfusion/ej2-calendars/styles/material.css';  
// import '@syncfusion/ej2-dropdowns/styles/material.css';  
// import '@syncfusion/ej2-inputs/styles/material.css';  
// import '@syncfusion/ej2-navigations/styles/material.css';
// import '@syncfusion/ej2-popups/styles/material.css';
// import '@syncfusion/ej2-splitbuttons/styles/material.css';
// import '@syncfusion/ej2-notifications/styles/material.css';
// import "@syncfusion/ej2-vue-grids/styles/material.css";

// import { registerLicense } from '@syncfusion/ej2-base';
// import { GridPlugin, GridComponent, ColumnsDirective, ColumnsPlugin, ColumnDirective, ColumnPlugin} from "@syncfusion/ej2-vue-grids";

// registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0diWH5cdHdXQWhbU0A=');


import MenuLeft from './components/MenuLeft.vue';
import TipTap from './components/TipTap.vue';
// import Tabs from './views/Tabs.vue';
// import Tab1 from './views/Tab1.vue';
// import Modal from './components/Modal.vue';

import { defineCustomElements } from '@ionic/pwa-elements/loader'; // https://ionicframework.com/docs/vue/your-first-app

defineCustomElements(window); // https://ionicframework.com/docs/vue/your-first-app

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md',
    swipeBackEnabled: false,
    rippleEffect: false,
    // rippleEffect: true,
    // animated: false,
  })
  .use(router)
  .use(store)
  // .use(GridPlugin)
  // .use(ReactiveProvide)


app.config.unwrapInjectedRef = true
app.component('menu-left', MenuLeft)
app.component('tip-tap', TipTap)
// app.component('tabs', Tabs)
// app.component('tab1', Tab1)
// app.component('modal', Modal)

// app.component(GridPlugin.name, GridComponent);
// app.component(ColumnsPlugin.name, ColumnsDirective);
// app.component(ColumnPlugin.name, ColumnDirective);

router.isReady().then(() => {
  app.mount('#app')
})