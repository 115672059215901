<template>
  <!-- <tip-tap /> -->
  <ion-app>
    <ion-router-outlet></ion-router-outlet>
    <!-- <tip-tap /> -->
  </ion-app>
</template>

<script>

import { mapActions } from 'vuex'
import {
  IonApp,
  IonRouterOutlet,
  toastController,
} from '@ionic/vue'
import { defineComponent } from 'vue'
import { db, auth } from './firebaseDb';
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, doc, onSnapshot } from "firebase/firestore";
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  // computed: {
  //   ...mapState(['isLoading']),
  // },
  onBeforeUnmount: function() {
    authListener()
  },
  // created() {
  beforeCreate() {

    // db.enablePersistence({synchronizeTabs: true}).catch((err) => {
    //   console.log(err)
    // });

    // console.log("beforeCreate App.vue")

    onAuthStateChanged(auth, user => {
      if (user !== null) {


        this.getFirebaseItems(user.uid);
        this.saveUserProfile(user);
        // console.log(user.email)

      }
    });

    // document.addEventListener('swUpdated', this.updateAvailable, { once: true })

  },
  mounted: function() {
    // console.log('mounted');

  },
  setup() {

    const router = useRouter()
    const authListener = onAuthStateChanged(auth, user => {
      if (!user) {
        console.log('Redirecting to login page');
        router.push('/tabs/tab1');
      }
    })

    // const registration = ref(null)
    // const updateExists = ref(false)

    return {
      authListener,
      // registration,
      // updateExists,
    }
  },
  methods: {
    // async openToast(header, message) {
    async openToast(message) {
      const toast = await toastController
        .create({
          // "header": header,
          "message": message,
          "duration": 2000,
          // "mode": "md",
          "mode": "ios",
          // "translucent": true,
          "color": "medium",
          // "buttons": [
          //   {
          //     text: 'Ok',
          //     role: 'cancel',
          //     handler: () => {
          //       toast.dismiss()
          //     }
          //   }
          // ]
        })
      return toast.present();
    },

    // updateAvailable(event) {
    //   console.log(event)

    //   this.registration = event.detail
    //   this.updateExists = true
    // },
    ...mapActions(['updateStoredItems']),
    commitFirebaseItems: function (firestoreItems) {

      // console.log("committed firestore to vuex")
      let payload = this.storedItems
      payload = firestoreItems
      this.updateStoredItems(payload)
      // console.log(payload)
    },

    // ...mapActions(['updateStoredItems']),
    ...mapActions(['updateUserSettings']),
    commitFirebaseSettings: function (passedUserSettings) {

      // console.log("committed firestore to vuex")
      // console.log(passedUserSettings)

      let payload = this.userSettings
      payload = passedUserSettings
      this.updateUserSettings(payload)

    },
    ...mapActions(['updateReminders']),
    commitReminders: function (passedReminders) {

      let payload = this.reminders
      payload = passedReminders
      this.updateReminders(payload)

    },
    ...mapActions(['updateUserFiles']),
    commitUserFiles: function (passedUserFiles) {

      let payload = this.userFiles
      payload = passedUserFiles
      this.updateUserFiles(payload)

    },
    ...mapActions(['updateUserContacts']),
    commitUserContacts: function (passedUserContacts) {

      let payload = this.userContacts
      payload = passedUserContacts
      this.updateUserContacts(payload)

    },

    // ...mapActions(['updateStoredItems']),
    getFirebaseItems: function (user_uid) {

      // console.log("getting firebase items now")
      // var user = auth.currentUser;
      // console.log(user.uid)
      // db.collection('items').orderBy("time").onSnapshot((querySnapshot) => {
      // db.collection('items').where("author", "==", user.uid).onSnapshot((querySnapshot) => {
      // db.collection('items').where("author", "==", "user_id_1").onSnapshot((querySnapshot) => {
      // db.collection('items').where("read", "array-contains", "user_id_1").onSnapshot((querySnapshot) => {
      // db.collection('items').where("read", "array-contains", "user_id_2").onSnapshot((querySnapshot) => {
        // db.collection('items').where("author", "==", this.whoIsUser.uid).onSnapshot((querySnapshot) => {
          // db.collection('items').where("author", "==", user.uid).get().then((querySnapshot) => {
            
            // console.log(querySnapshot)

      // db.collection('items').orderBy("time").where("read", "array-contains", user_uid).onSnapshot((querySnapshot) => {
      // db.collection('items').orderBy("modified", "desc").where("read", "array-contains", user_uid).onSnapshot((querySnapshot) => {


      // Get userSettings (used to store user-specific preferences such as pinned lists and item sort order)
      // db.collection("userSettings")


      // db.collection("userSettings").doc(user_uid)
      // .onSnapshot((doc) => {

      onSnapshot(doc(db, "userSettings", user_uid), (doc) => {

        // console.log("trying")

        var docData = doc.data();

        if(docData) {
          // console.log("exists")
          this.commitFirebaseSettings(doc.data())
        } else {
          // console.log("nope")
          this.commitFirebaseSettings({})
        }

      });

      // db.collection("reminders").doc(user_uid)
      // .onSnapshot((doc) => {

      onSnapshot(doc(db, "reminders", user_uid), (doc) => {

        var docData = doc.data();

        if(docData) {
          // console.log("exists")
          this.commitReminders(doc.data())
        } else {
          // console.log("nope")
          this.commitReminders({})
        }

      });

      // db.collection("userFiles").doc(user_uid)
      // .onSnapshot((doc) => {

      onSnapshot(doc(db, "userFiles", user_uid), (doc) => {

        var docData = doc.data();

        if(docData) {
          // console.log("exists")
          this.commitUserFiles(doc.data())
        } else {
          // console.log("nope")
          this.commitUserFiles({})
        }

      });

      // db.collection("contacts").doc(user_uid)
      // .onSnapshot((doc) => {

      onSnapshot(doc(db, "contacts", user_uid), (doc) => {

        var docData = doc.data();

        if(docData) {
          // console.log("exists")
          this.commitUserContacts(doc.data())
        } else {
          // console.log("nope")
          this.commitUserContacts({})
        }

      });

      // Get user's 'items' (lists and list items)
      // db.collection('items').where("read", "array-contains", user_uid).onSnapshot((querySnapshot) => {


      const q = query(collection(db, "items"), where("read", "array-contains", user_uid));
      onSnapshot(q, (querySnapshot) => {
        var localItemObject = {}
        // var localItemArray = []

        querySnapshot.forEach((doc) => {

          let item = doc.data()

          item.id = doc.id

          localItemObject[item.id] = item
          // localItemArray.push(item)

        })

        // console.log(localItemObject)
        // console.log(localItemArray)

        // let payload = this.storedItems
        // payload = localItemObject
        // this.updateStoredItems(payload)
        this.commitFirebaseItems(localItemObject)
        // this.commitFirebaseItems(localItemArray)

        // can I get rid of the .forEach block and just use the querySnapshot object itself?
        // instead of commiting this data to the vuex store in this function, maybe I should just
        // save the object as a prop, then emit a message which would invoke a listener to grab
        // the prop value (object), and then commit it to the vuex store? Maybe breaking it up
        // might help performance and enable offline for mobile PWA once again like it was befoe I
        // started using vuex?
      })

    },
    ...mapActions(['updateUserProfile']),
    saveUserProfile: function (user) {

      const displayName = user.displayName;
      const email = user.email;
      const photoURL = user.photoURL;
      const emailVerified = user.emailVerified;
      const userUID = user.uid;

      var newUserObject = {
        "displayName": displayName,
        "email": email,
        "photoURL": photoURL,
        "emailVerified": emailVerified,
        "userUID": userUID,
      }


      // console.log(newUserObject)

      let payload = this.storedUserProfile
      payload = newUserObject
      this.updateUserProfile(payload)

    },
    ...mapActions(['updateStoredItems']),
    ...mapActions(['updateUserProfile']),
    signOut: function () {

      let storedItemPayload = this.storedItems
      storedItemPayload = {}
      this.updateStoredItems(storedItemPayload)

      let storedUserPayload = this.storedUserProfile
      storedUserPayload = {}
      this.updateUserProfile(storedUserPayload)

    },

  },

});
</script>

<style>

  ion-popover ion-backdrop {
    display: none;
  }
  ion-backdrop {
    display: none;
  }
  ion-title {
    font-size: 1.7rem;
  }
  ion-item:last-child {
    --border-style: none;
  }
  /* ion-modal::part(backdrop) {
    background-color: #cccccc;
    --backdrop-opacity: .2;
  } */
  ion-modal ion-page {
    border: 1px solid #c9c0c040;
  }

  /* @media only screen and (min-width: 768px) and (min-height: 768px) { */
  @media only screen and (min-width: 768px) {

    ion-modal {
      /* --width: 85%;
      --height: 90%; */
      --width: 100%;
      --height: 100%;
      --border-radius: 0;
    }

    /* ion-page { */
    ion-nav .ion-page {
      /* border: 1px solid white; */
      /* border-radius: 0.8rem; */
      border: var(--mikes-card-border);
    }
    ion-grid {
      width: 80%;
    }
    /* ion-content ion-header {
      width: 80%;
      padding-left: 20%;
      padding-right: 20%;
    } */

  }

  .trash-add-reorder .trash ion-icon {
    color: #ffffff;
  }
  .trash-add-reorder .add ion-icon {
    color: var(--mikes-icon-color);
  }
  .trash-add-reorder .reorder ion-icon {
    color: var(--mikes-icon-color);
  }
  .cancelSave ion-icon {
    color: var(--mikes-cancelSave);
  }
  .cancelCheck ion-icon {
    color: var(--mikes-cancelCheck);
  }
  .delete-note ion-icon {
    color: var(--ion-color-danger);
  }
  .delete-reminder-icon ion-icon {
    color: var(--ion-color-danger);
  }

</style>