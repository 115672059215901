export function convObjectToArray(passedObject) {

    // console.log(passedObject)

    let firstCopy = Object.keys(passedObject).map(key => {

        let ar = passedObject[key]
        ar.key = key

        return ar
    })

    return firstCopy
}

export function convObjectToListArray(passedObject) {

    let firstCopy = Object.keys(passedObject).map(key => {

        let ar = passedObject[key]
        ar.key = key

        var emptyObj = {
            key: key,
            id: ar.id,
            modified: ar.modified,
            name: ar.name,
            read: ar.read,
            write: ar.write,
            active: ar.active,
        }

        // return ar
        // console.log(emptyObj)
        return emptyObj
    })

    // console.log(firstCopy)
    return firstCopy
}


export function filterArrayActive(list) {
    return list.active != false;
}


export function filterArrayInactive(list) {
    return list.active == false;
}


// export function filterArrayInactiveByAuthor(array, userUID) {
//     console.log(userUID)
//     return array.author == userUID;
// }


// export function filterArrayByInactiveAndByAuthor(array, userUID) {
//     console.log(userUID)
//     // return (array.author == userUID && array.active == false);
//     return array.active == false;
// }


export function sortArrayByDeleted(list, order) {
    
    if(order==1) { // Descending
        list.sort((a, b) => {

            let compare = 0;
            if (b.deleted > a.deleted) {
                compare = 1;
            } else if (a.deleted > b.deleted) {
                compare = -1;
            }

            return compare;
        });
    } else { // Ascending

        list.sort((a, b) => {

            let compare = 0;
            if (b.deleted > a.deleted) {
                compare = 1;
            } else if (a.deleted > b.deleted) {
                compare = -1;
            }

            return compare;
        });

    }

    return list;
}