// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";
// import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { initializeApp } from "firebase/app";
// import { getFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
// import { getFirestore, persistentLocalCache } from "firebase/firestore";
import { getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
// import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
// const functions = require("firebase/functions");

// const firebaseConfig = {
//   apiKey: "AIzaSyCmkXUw798g9uZjitGgfbzaMrMyUBHWjjI",
//   authDomain: "cla-ionic-vue.firebaseapp.com",
//   projectId: "cla-ionic-vue",
//   storageBucket: "cla-ionic-vue.appspot.com",
//   messagingSenderId: "867363595242",
//   appId: "1:867363595242:web:fe9f4604fd9d6ca8eec893",
//   measurementId: "G-Y039VC0XPG"
// };



// const persistentLocalCacheSettings = {
//   multiTab: true,
// };

// const persistentLocalCache = persistentLocalCache(persistentLocalCacheSettings);

const firebaseConfig = {
  apiKey: "AIzaSyCmkXUw798g9uZjitGgfbzaMrMyUBHWjjI",
  authDomain: "cla-ionic-vue.firebaseapp.com",
  projectId: "cla-ionic-vue",
  storageBucket: "cla-ionic-vue.appspot.com",
  messagingSenderId: "867363595242",
  appId: "1:867363595242:web:fe9f4604fd9d6ca8eec893",
  measurementId: "G-Y039VC0XPG",
  // localCache: persistentLocalCache,
  // localCache: persistentLocalCache(persistentLocalCacheSettings),
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig, {
//   localCache: persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()}),
//   cacheSizeBytes: CACHE_SIZE_UNLIMITED,
// });


const app = initializeApp(firebaseConfig);

// Use multi-tab IndexedDb persistence.
initializeFirestore(app, 
  {localCache: 
    persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()})
  }
);

// firebase.firestore().settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
//   merge: true
// });

// export const fb = firebase;
export const db = getFirestore(app);
// export const db = getFirestore(app).settings({
//   cacheSizeBytes: 100 * 1024 * 1024 // 100 MB,
// });

// export const db = getFirestore(app, {
//   localCache: persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()}),
//   cacheSizeBytes: CACHE_SIZE_UNLIMITED,
// });
// export const db = initializeFirestore(app, {
//   localCache: persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()}),
//   cacheSizeBytes: CACHE_SIZE_UNLIMITED,
// });

export const functions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const fs_ref = ref


// const storage = firebase.storage();
// export const storageRef = storage.ref();