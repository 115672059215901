<template>

    <div v-if="editor">

        <div
            id="firstButtonContainer"
            style="position: fixed;
            top: 80px;
            z-index: 1;
            display: grid;"
        >

            <div class="buttonContainer">
                <!-- <div> -->
                    <!-- <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                        Left
                        <i class="ri-align-left ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                        Center
                        <i class="ri-align-center ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                        Right
                        <i class="ri-align-right ri-lg"></i>
                    </button> -->
                    <!-- <button @click="editor.chain().focus().setHorizontalRule().run()">
                        Divider
                        <i class="ri-separator ri-lg"></i>
                    </button> -->
                    <!-- <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
                        justify
                    </button> -->
                    <!-- <button @click="editor.chain().focus().unsetTextAlign().run()">
                        unsetTextAlign
                    </button> -->
                    <!-- <editor-content :editor="editor" /> -->
                <!-- </div> -->

                <!-- <floating-menu :editor="editor" :tippy-options="{ duration: 100 }">
                    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                        H1
                    </button>
                    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                        H2
                    </button>
                    <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                        Bullet List
                    </button>
                </floating-menu> -->

                <!-- <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor" id="bubble-menu-container"> -->
                    <!-- <div>
                        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                            Bold
                            <i class="ri-bold ri-lg"></i>
                        </button>
                        <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                            Italic
                            <i class="ri-italic ri-lg"></i>
                        </button>
                        <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                            + Underline
                            <i class="ri-underline ri-lg"></i>
                        </button>
                        <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                            strike
                        </button>
                    </div> -->

                    <!-- <div> -->
                        <!-- <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                            + Underline
                            <i class="ri-underline ri-lg"></i>
                        </button> -->
                        <!-- <button @click="editor.chain().focus().setUnderline().run()" :disabled="editor.isActive('underline')">
                            + Underline
                            <i class="ri-underline ri-lg"></i>
                        </button> -->
                        <!-- <button @click="editor.chain().focus().unsetUnderline().run()" :disabled="!editor.isActive('underline')">
                            - Underline
                            <i class="ri-format-clear ri-lg"></i>
                        </button> -->
                    <!-- </div> -->

                    <!-- <div>
                        <h6 style="color: black; margin-bottom: 0; font-size: 10px;">Highlights</h6>
                        <button @click="editor.chain().focus().toggleHighlight({ color: '#f7f789' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#f7f789' }) }">
                            Yellow
                            <div class="colorContainer" style="background: #f7f789;"></div>
                        </button>
                        <button @click="editor.chain().focus().toggleHighlight({ color: '#8ce99a' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#8ce99a' }) }">
                            Green
                            <div class="colorContainer" style="background: #8ce99a;"></div>
                        </button>
                        <button @click="editor.chain().focus().toggleHighlight({ color: '#ffa8a8' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#ffa8a8' }) }">
                            Red
                            <div class="colorContainer" style="background: #ffa8a8;"></div>
                        </button>
                        <button @click="editor.chain().focus().toggleHighlight({ color: '#74c0fc' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#74c0fc' }) }">
                            Blue
                            <div class="colorContainer" style="background: #74c0fc;"></div>
                        </button>
                        <button
                            @click="editor.chain().focus().unsetHighlight().run()"
                            :disabled="!editor.isActive('highlight')"
                        >
                            Remove
                            <i class="ri-format-clear ri-lg"></i>
                        </button>
                    </div> -->

                    <!-- <div>
                        <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                            Set Link
                            <i class="ri-link ri-lg"></i>
                        </button>
                        <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                            Unlink
                            <i class="ri-link-unlink ri-lg"></i>
                        </button>
                    </div> -->

                <!-- </bubble-menu> -->

                <!-- <div class="character-count">
                    {{ editor.storage.characterCount.characters() }}/{{ limit }} characters
                    <br>
                    {{ editor.storage.characterCount.words() }} words
                </div> -->

                <!-- <div> -->
                    <!-- <input
                        type="color"
                        @input="editor.chain().focus().setColor($event.target.value).run()"
                        :value="editor.getAttributes('textStyle').color"
                    > -->

                    <!-- <button @click="editor.chain().focus().setColor('#dd281a').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#dd281a' })}">
                        Red
                    </button>
                    <button @click="editor.chain().focus().setColor('#001ec3').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#001ec3' })}">
                        Blue
                    </button>
                    <button @click="editor.chain().focus().setColor('#2d8e31').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#2d8e31' })}">
                        Green
                    </button>
                    <button @click="editor.chain().focus().setColor('#e1ac0c').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#e1ac0c' })}">
                        Yellow
                    </button>
                    <button @click="editor.chain().focus().unsetColor().run()">
                        Reset
                    </button> -->
                <!-- </div> -->

                <div class="buttonSubContainer">
                    <!-- <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                        Title1
                    </button>
                    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                        Title2
                    </button> -->
                    <button
                        v-if="editor.can().liftListItem('taskItem')"
                        @click="editor.chain().focus().liftListItem('taskItem').run()"
                        :disabled="!editor.can().liftListItem('taskItem')"
                    >
                        <!-- liftListItem('taskItem') -->
                        <i class="ri-arrow-left-line ri-lg"></i>
                    </button>
                    <button
                        v-else-if="editor.can().liftListItem('listItem')"
                        @click="editor.chain().focus().liftListItem('listItem').run()"
                        :disabled="!editor.can().liftListItem('listItem')"
                    >
                        <!-- liftListItem('listItem') -->
                        <i class="ri-arrow-left-line ri-lg"></i>
                    </button>
                    <button
                        v-if="editor.can().sinkListItem('taskItem')"
                        @click="editor.chain().focus().sinkListItem('taskItem').run()"
                        :disabled="!editor.can().sinkListItem('taskItem')"
                    >
                        <!-- sinkListItem('taskItem') -->
                        <i class="ri-arrow-right-line ri-lg"></i>
                    </button>
                    <button
                        v-else-if="editor.can().sinkListItem('listItem')"
                        @click="editor.chain().focus().sinkListItem('listItem').run()"
                        :disabled="!editor.can().sinkListItem('listItem')"
                    >
                        <!-- sinkListItem('listItem') -->
                        <i class="ri-arrow-right-line ri-lg"></i>
                    </button>

                    <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                        <!-- Bullet Point -->
                        <i class="ri-list-unordered ri-lg"></i>
                    </button>
                    <!-- <button @click="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }"> -->
                    <button @click.prevent="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
                        <!-- Task -->
                        <i class="ri-list-check-2 ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                        <!-- Numbered -->
                        <i class="ri-list-ordered ri-lg"></i>
                    </button>
                    <!-- <button @click="editor.chain().focus().liftListItem('taskItem').run()" :class="{ 'is-active': editor.isActive('liftListItem') }">
                        <i class="ri-indent-decrease ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().sinkListItem('taskItem').run()" :class="{ 'is-active': editor.isActive('sinkListItem') }">
                        <i class="ri-indent-increase ri-lg"></i>
                    </button> -->
                    <button @click="editor.chain().focus().setHorizontalRule().run()" :class="{ 'is-active': editor.isActive('horizontalRule') }">
                        <!-- Divider -->
                        <i class="ri-separator ri-lg"></i>
                    </button>
                </div>

                <div class="buttonSubContainer">
                    <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                        <!-- Bold -->
                        <i class="ri-bold ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                        <!-- Italic -->
                        <i class="ri-italic ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                        <!-- + Underline -->
                        <i class="ri-underline ri-lg"></i>
                    </button>
                    <!-- <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                        strike
                    </button> -->
                </div>


                <div class="buttonSubContainer">
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#f7f789' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#f7f789' }) }">
                        <div class="colorContainer" style="background: #f7f789;"></div>
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#8ce99a' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#8ce99a' }) }">
                        <div class="colorContainer" style="background: #8ce99a;"></div>
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#ffa8a8' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#ffa8a8' }) }">
                        <div class="colorContainer" style="background: #ffa8a8;"></div>
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#74c0fc' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#74c0fc' }) }">
                        <div class="colorContainer" style="background: #74c0fc;"></div>
                    </button>
                </div>

                <!-- <div>
                    <button @click="editor.chain().focus().setUnderline().run()" :disabled="editor.isActive('underline')">
                        setUnderline
                    </button>
                    <button @click="editor.chain().focus().unsetUnderline().run()" :disabled="!editor.isActive('underline')">
                        unsetUnderline
                    </button>
                </div> -->


                <!-- <div>
                    <button @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
                        toggleHighlight
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#ffc078' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#ffc078' }) }">
                        orange
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#8ce99a' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#8ce99a' }) }">
                        green
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#74c0fc' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#74c0fc' }) }">
                        blue
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#b197fc' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#b197fc' }) }">
                        purple
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: 'red' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: 'red' }) }">
                        red ('red')
                    </button>
                    <button @click="editor.chain().focus().toggleHighlight({ color: '#ffa8a8' }).run()" :class="{ 'is-active': editor.isActive('highlight', { color: '#ffa8a8' }) }">
                        red (#ffa8a8)
                    </button>
                    <button
                        @click="editor.chain().focus().unsetHighlight().run()"
                        :disabled="!editor.isActive('highlight')"
                    >
                        unsetHighlight
                    </button>
                </div> -->


                <!-- <div>
                    <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                        setLink
                    </button>
                    <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                        unsetLink
                    </button>
                </div> -->


                <!-- <div> -->
                    <!-- <button @click="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
                        Task
                    </button> -->
                    <!-- <button @click="editor.chain().focus().splitListItem('taskItem').run()" :disabled="!editor.can().splitListItem('taskItem')">
                        Split
                    </button>
                    <button @click="editor.chain().focus().sinkListItem('taskItem').run()" :disabled="!editor.can().sinkListItem('taskItem')">
                        Sink
                    </button>
                    <button @click="editor.chain().focus().liftListItem('taskItem').run()" :disabled="!editor.can().liftListItem('taskItem')">
                        Lift
                    </button> -->
                <!-- </div> -->


                <div class="buttonSubContainer">
                    <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
                        <!-- toggleBlockquote -->
                        <i class="ri-double-quotes-r ri-lg"></i>
                    </button>
                    <button @click="addImage">
                        <!-- setImage -->
                        <i class="ri-image-add-fill ri-lg"></i>
                    </button>
                    <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                        <i class="ri-link ri-lg"></i>
                    </button>
                    <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                        <i class="ri-link-unlink ri-lg"></i>
                    </button>
                </div>


                <div class="buttonSubContainer">
                    <button
                        @click="editor.chain().focus().undo().run()"
                        :disabled="!editor.can().undo()"
                    >
                        <!-- Undo -->
                        <i class="ri-arrow-go-back-line ri-lg"></i>
                    </button>
                    <button
                        @click="editor.chain().focus().redo().run()"
                        :disabled="!editor.can().redo()"
                    >
                        <!-- Redo -->
                        <i class="ri-arrow-go-forward-line ri-lg"></i>
                    </button>
                    <!-- <button
                        @click="editor.chain().focus().sinkListItem('taskItem').run() &&
                        editor.chain().focus().sinkListItem('listItem').run()"
                        :disabled="!editor.can().sinkListItem('taskItem') || !editor.can().sinkListItem('listItem')"
                    >
                        <i class="ri-contract-right-line"></i>
                    </button> -->

                </div>


            </div>

        </div>


        <div class="buttonContainer" v-if="true==false">

            <div class="buttonSubContainer">
            <!-- <div class="tableButtonSubContainer"> -->

                <button @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
                    <!-- insertTable -->
                    <i class="ri-table-line ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().addColumnBefore().run()">
                    <!-- addColumnBefore -->
                    <i class="ri-insert-column-left ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().addColumnAfter().run()">
                    <!-- addColumnAfter -->
                    <i class="ri-insert-column-right ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().deleteColumn().run()">
                    <!-- deleteColumn -->
                    <i class="ri-delete-column ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().addRowBefore().run()">
                    <!-- addRowBefore -->
                    <i class="ri-insert-row-top ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().addRowAfter().run()">
                    <!-- addRowAfter -->
                    <i class="ri-insert-row-bottom ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().deleteRow().run()">
                    <!-- deleteRow -->
                    <i class="ri-delete-row ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().deleteTable().run()">
                    <!-- deleteTable -->
                    <i class="ri-delete-bin-line ri-lg"></i>
                </button>
                <!-- <button @click="editor.chain().focus().mergeCells().run()">
                    mergeCells
                    <i class="ri-merge-cells-horizontal ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().splitCell().run()">
                    splitCell
                    <i class="ri-split-cells-horizontal ri-lg"></i>
                </button> -->
                <button @click="editor.chain().focus().mergeOrSplit().run()">
                    <!-- mergeOrSplit -->
                    <i class="ri-merge-cells-horizontal ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().toggleHeaderColumn().run()">
                    <!-- toggleHeaderColumn -->
                    <i class="ri-layout-column-fill ri-lg"></i>
                </button>
                <button @click="editor.chain().focus().toggleHeaderRow().run()">
                    <!-- toggleHeaderRow -->
                    <i class="ri-layout-row-fill ri-lg"></i>
                </button>
                <!-- <button @click="editor.chain().focus().toggleHeaderCell().run()">
                    toggleHeaderCell
                </button> -->
                <!-- <button @click="editor.chain().focus().setCellAttribute('colspan', 2).run()">
                    setCellAttribute
                </button> -->
                <!-- <button @click="editor.chain().focus().fixTables().run()">
                    fixTables
                </button> -->
                <!-- <button @click="editor.chain().focus().goToNextCell().run()">
                    goToNextCell
                </button>
                <button @click="editor.chain().focus().goToPreviousCell().run()">
                    goToPreviousCell
                </button> -->

            </div>

        </div>


        <editor-content style="margin-top: 85px;" :editor="editor" />

    </div>

</template>

<script>
import { ref, defineComponent } from 'vue';
import {
    Editor,
    EditorContent,
    FloatingMenu,
    BubbleMenu,
} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
// import Focus from '@tiptap/extension-focus'
// import History from '@tiptap/extension-history'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
// import HorizontalRule from '@tiptap/extension-horizontal-rule'
// import Gapcursor from '@tiptap/extension-gapcursor'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Image from '@tiptap/extension-image'
import FileHandler from '@tiptap-pro/extension-file-handler'
// import Blockquote from '@tiptap/extension-blockquote'

// const CustomDocument = Document.extend({
//   content: 'heading block*',
// })

export default defineComponent({
    name: 'TipTap',
    components: {
        EditorContent,
        FloatingMenu,
        BubbleMenu,
    },
    props: [
        'modelValue',
    ],
    // props: {
    //     modelValue: {
    //         default: {
    //             shouldUpdate: true,
    //             value: "Hmm... That's weird.",
    //         },
    //         type: Object,
    //         // required: true,
    //     }
    // },
    setup(props) {

        const setupModelValue = ref(props.modelValue)
        // console.log('setupModelValue', setupModelValue)

        return {
            setupModelValue,
            // computedModalValue: computed(() => {
            //     return props.modelValue;
            // })
        }
    },
    emits: ['passBack'],
    data() {
        return {
            editor: null,
            // limit: 4000,
            limit: 8000,
        }
    },
    watch: {
        modelValue(newModelValue, oldModelValue) {
        // modelValue(val, oldVal) {

            // console.log("watch")

            // if(val) {
            //     console.log("val")
            //     console.log(val)
            // }
            // if(oldVal) {
            //     console.log("oldVal")
            //     console.log(oldVal)
            // }

            // // HTML
            // // const isSame = this.editor.getHTML() === value

            // // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            // if (isSame) {
            //     return
            // }

            // this.editor.commands.setContent(newModelValue, false)

            // if(newModelValue != oldModelValue) {
            if(newModelValue.shouldUpdate) {

                // console.log("watched and value updated")

                // console.log('newModelValue', newModelValue)
                // console.log('oldModelValue', oldModelValue)

                this.setupModelValue = newModelValue
                this.editor.commands.setContent(newModelValue.value, false)
                // this.editor.commands.setContent(newModelValue, true)
            }
            // this.editor.commands.setContent(newModelValue, true)
            // this.editor.commands.setContent(val, false)
            // this.editor.commands.setContent(oldVal, false)
        },
    },
    mounted() {
        // console.log('setupModelValue', this.setupModelValue)
        // console.log('modelValue', this.modelValue)

        this.editor = new Editor({
            extensions: [
                // CustomDocument,
                // StarterKit,
                StarterKit.configure({
                    // document: false,
                    // OrderedList: false,
                    // OrderedList: true,
                    // HorizontalRule: true,
                    // OrderedList: {
                    //     HTMLAttributes: {
                    //         class: 'ordered-list-class',
                    //     },
                    // },
                    // heading: {
                    //     levels: [1, 2],
                    // },
                }),
                // Placeholder,
                Placeholder.configure({
                    // Use a placeholder:
                    placeholder: 'Add a new item...',
                    // Use different placeholders depending on the node type:
                    // placeholder: ({ node }) => {
                    //   if (node.type.name === 'heading') {
                    //     return 'What’s the title?'
                    //   }

                    //   return 'Can you add some further context?'
                    // },
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                CharacterCount.configure({
                    limit: this.limit,
                }),
                TextStyle,
                Color,
                // Focus.configure({
                //     className: 'has-focus',
                //     mode: 'all',
                // }),
                // History,
                // Typography,
                Typography.configure({
                    oneHalf: false,
                    oneQuarter: false,
                    threeQuarters: false,
                }),
                Underline,
                Highlight.configure({ multicolor: true }),
                // Link,
                Link.configure({
                    // openOnClick: false,
                    validate: href => /^https?:\/\//.test(href),
                }),
                TaskList,
                TaskItem.configure({
                    nested: true,
                }),

                FileHandler.configure({
                    // allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
                    onDrop: (currentEditor, files, pos) => {
                    files.forEach(file => {
                        const fileReader = new FileReader()

                        fileReader.readAsDataURL(file)
                        fileReader.onload = () => {

                            console.log("drop")
                            console.log(file)
                            currentEditor.chain().insertContentAt(pos, {
                                type: 'image',
                                attrs: {
                                src: fileReader.result,
                                },
                            }).focus().run()
                        }
                    })
                    },
                    onPaste: (currentEditor, files) => {
                    files.forEach(file => {
                        const fileReader = new FileReader()

                        fileReader.readAsDataURL(file)
                        fileReader.onload = () => {

                            console.log("paste")
                            console.log(file)
                            currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                                type: 'image',
                                attrs: {
                                src: fileReader.result,
                                },
                            }).focus().run()
                        }
                    })
                    },
                }),
                // TaskItem,
                // OrderedList,
                // OrderedList.configure({
                //     HTMLAttributes: {
                //         class: 'ordered-list-class',
                //     },
                // })
                // ListItem,
                // HorizontalRule,
                // Gapcursor,
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                Image,
                // Blockquote,
            ],
            // autofocus: false,
            autofocus: true,
            // content: this.modelValue,
            // content: this.modelValue.value,
            content: this.setupModelValue.value,
            // content: this.computedModalValue,
            onUpdate: () => {
                // console.log("updated")
                // HTML
                // this.$emit('update:modelValue', this.editor.getHTML())

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
                this.$emit('passBack', this.editor.getText(), this.editor.getHTML())
                // console.log(this.editor.getText())
            },
        })
    },
    methods: {
        addImage() {
            const url = window.prompt('URL')

            if (url) {
                this.editor.chain().focus().setImage({ src: url }).run()
            }
        },
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
    },
    beforeUnmount() {
        this.editor.destroy()
    },

})
</script>

<style>
.is-active {
    border: 2px solid rgb(96, 146, 190) !important;
    /* background: rgb(172 216 255) !important; */
}
.has-focus {
    border-radius: 3px;
    box-shadow: 0 0 0 3px #68cef8;
}
.buttonSubContainer button {
    border: 1px solid #00000042;

    /* background: aliceblue;
    color: black; */

    /* Dark */
    /* background: #363636;
    color: #ffffff; */

    /* New */
    background: var(--mikes-buttonSubContainerButton-background);
    color: var(--mikes-buttonSubContainerButton-color);

    border-radius: 4px;
    margin: 2px;
    height: 2rem;
    width: 3rem;

    display: inline-flex;
    justify-content: center;
    align-items: center;
}
#bubble-menu-container {
    background: #fff8bb;
    border: 1px solid rgba(0, 0, 0, 0.263);
    border-radius: 4px;
    padding: 2px;
}
mark {
    /* background-color: #ffe066; */
    /* padding: 0.125em 0; */
    border-radius: 0.25em;
    box-decoration-break: clone;
    border-bottom: 1px #00000026 solid;
    padding: 0.3rem;
    /* box-shadow: rgb(0 0 0 / 25%) 0px 9px 14px; */
}
/* .ProseMirror p { */
/* .ProseMirror {
    margin-top: 1rem;
    padding-top: 20px;
} */
/* .ProseMirror.p:nth-of-type(1) { */
/* .ProseMirror.p:first-child { */
/* .ProseMirror:first-child { */
/* .ProseMirror p:nth-of-type(1) { */
/* .ProseMirror.p > p:first-child { */
/* .ProseMirror > p:first-letter { */
/* .ProseMirror *:first-letter {
    text-transform: uppercase;
} */
.ProseMirror > p:first-child {
    font-weight: bold;
    font-size: 2rem;
    position: sticky;
    top: 80px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    margin: 0;
    background: var(--ion-background-color, #fff);
}
/* .ProseMirror:active { */
.ProseMirror:focus-visible {
    border: none;
    outline: none;
    /* background: aliceblue; */
}
/* p:first-child {
    margin-top: 15px;
} */
p {
    font-size: 1.6rem;
    /* line-height: 2.5rem; */
    line-height: 2rem;
    /* margin-top: 1.6rem; */
    /* margin-bottom: 1.6rem; */
}
/* li p {
    margin-top: 1rem;
    margin-bottom: 1rem;
} */
a {
    cursor: pointer;
}

ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    padding-left: 10px;
}

ul[data-type="taskList"] p {
    /* margin: 0; */
    margin-top: 0.2rem;
    margin-bottom: 0rem;
}

ul[data-type="taskList"] li {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
}

ul[data-type="taskList"] li label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
    /* height: 2.5rem; */
    height: 1.6rem;
    margin-top: 4px;
}

ul[data-type="taskList"] li div {
    flex: 1 1 auto;
}

input[type=checkbox], input[type=radio] {
    /* height: 2.5rem; */
    /* width: 20px; */
    /* height: 1.6rem; */
    /* height: 25px;
    width: 25px; */
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.buttonContainer {

    /* background: var(--mikes-footer-toolbar-background); */

    /* Dark */
    /* background: none; */

    /* New */
    background: var(--mikes-buttonContainer-background);

    padding: 2px;
    border-bottom: 1px solid #00000021;
    justify-content: space-between;
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-gutter: stable;
}
.ordered-list-class {
    color: #68cef8;
}
::marker {
    width: 20px;
    height: 1.6rem;
    font-size: 1.6rem;
}
/* hr.ProseMirror-selectednode {
    border-top: 1px solid #68CEF8;
} */
hr {
    /* background: #00000063; */
    background: var(--mikes-divider);
}
.colorContainer {
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.384);
    width: 80%;
    height: 80%;
    /* height: 1.4rem; */
    /* width: 1.4rem; */
    /* text-align: center; */
    /* vertical-align: middle; */
}
.ri-lg {
    vertical-align: middle;
    font-size: 1.4rem;
}
/* .buttonContainer::-webkit-scrollbar {
    display: none;
} */
/* Link explaining how to successfully hide/format the scrollbar */
/* https://stackoverflow.com/questions/3296644/hiding-the-scroll-bar-on-an-html-page#13184693 */
.buttonContainer::-webkit-scrollbar {
    /* display: none; */
    /* background: rgb(96, 146, 190); */
    /* width: 2px; */
    height: 5px;
}             /* 1 */
.buttonContainer::-webkit-scrollbar-button {
    /* background: rgb(96, 146, 190); */
    display: none;
}      /* 2 */
.buttonContainer::-webkit-scrollbar-track {
    /* background: rgb(96, 146, 190); */
    display: none;
}       /* 3 */
.buttonContainer::-webkit-scrollbar-track-piece {
    /* background: rgb(96, 146, 190); */
    display: none;
} /* 4 */
.buttonContainer::-webkit-scrollbar-thumb {
    background: #3175eb9f;
    border-radius: 1rem;
    /* display: none; */
}       /* 5 */
.buttonContainer::-webkit-scrollbar-corner {
    /* background: rgb(96, 146, 190); */
    display: none;
}      /* 6 */
.buttonContainer::-webkit-resizer {
    /* background: rgb(96, 146, 190); */
    display: none;
}               /* 7 */


.ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.ProseMirror table td,
.ProseMirror table th {
    min-width: 1em;
    border: 1px solid #000000;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
}

.ProseMirror table th {
    font-weight: bold;
    text-align: center;
    background-color: #98ccff;
}

.ProseMirror table .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0; right: 0; top: 0; bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
}

.ProseMirror table .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    pointer-events: none;
}

.ProseMirror table p {
    margin: 0;
}


.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}


.ProseMirror img {
    /* max-width: 100%; */
    max-width: 15rem;
    height: auto;
    cursor: pointer;
    border: 1px solid #0000004d;
    /* padding: 1rem; */
    min-width: 15rem;
    min-height: 15rem;
    background: #d7ecfd;
    border-radius: 1rem;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.76);
    margin-left: 1rem;
}
.ProseMirror-selectednode {
    outline: 3px solid #68CEF8;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 3px solid #c8c8c8;
    color: #6d6d6d;
}

.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
}
</style>