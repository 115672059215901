import Vuex from 'vuex'
import {
  convObjectToArray,
  convObjectToListArray,
  filterArrayActive,
  filterArrayInactive,
  // filterArrayInactiveByAuthor,
  // filterArrayByInactiveAndByAuthor,
  sortArrayByDeleted,
} from "./vuexFunctions"


export default new Vuex.Store({
  state: {
    // itemData: {}, // Object of items in selected list
    // itemDataCopy: {}, // Original copy of object of items in selected list
    // displayedList: '', // Name of selected list
    // displayedItem: {}, // Object of details for displayed item
    isLoading: false, // Boolean for whether the ion-loading component should be shown
    // listNames: {},
    // listNamesCopy: {},
    // listCount: false,
    storedItems: {},
    userSettings: {},
    reminders: {},
    userFiles: {},
    userContacts: {},
    // listBeingViewed: {},
    storedUserProfile: {},
  },
  mutations: {
    // SET_ITEM_DATA(state, value) {
    //   state.itemData = value
    // },
    SET_FIREBASE_ITEMS(state, value) {
      // state.storedItems = value
      // Object.freeze(value);
      state.storedItems = Object.freeze(value)
      // state.storedItems = Object.freeze(convObjectToArray(value)).filter(filterArrayActive)
    },
    SET_USER_SETTINGS(state, value) {
      // state.userSettings = value
      state.userSettings = Object.freeze(value)
    },
    SET_REMINDERS(state, value) {
      // state.reminders = value
      state.reminders = Object.freeze(value)
    },
    SET_USER_FILES(state, value) {
      // state.userFiles = value
      state.userFiles = Object.freeze(value)
    },
    SET_USER_CONTACTS(state, value) {
      // state.userContacts = value
      state.userContacts = Object.freeze(value)
    },
    // SET_LIST_BEING_VIEWED(state, value) {
    //   state.listBeingViewed = value
    // },
    SET_USER_PROFILE(state, value) {
      // state.storedUserProfile = value
      state.storedUserProfile = Object.freeze(value)
    },
    // SET_ITEM_DATA_COPY(state, value) {
    //   state.itemDataCopy = value
    // },
    // SET_DISP_LIST(state, value) {
    //   state.displayedList = value
    // },
    // SET_DISP_ITEM(state, value) {
    //   state.displayedItem = value
    // },
    SET_IS_LOADING(state, value) {
      // state.isLoading = value
      state.isLoading = Object.freeze(value)
    },
    // SET_ITEM_DATA_FAV(state, value) {
    //   const copy = state.itemData;
      
    //   let index = copy.findIndex( element => {
    //     if (element.item_id === value.item_id) {
    //       return true;
    //     }
    //   });

    //   copy[index].fav_bool = value.fav_bool

    //   state.itemData = copy

    //   state.displayedItem.fav_bool = value.fav_bool
    // },
    // SET_ITEM_DATA_PRIORITY(state, value) {
    //   const copy = state.itemData;
      
    //   let index = copy.findIndex( element => {
    //     if (element.item_id === value.item_id) {
    //       return true;
    //     }
    //   });

    //   copy[index].priority = value.priority_bool

    //   state.itemData = copy

    //   state.displayedItem.priority = value.priority_bool
    // },

    // SET_LIST_DATA(state, value) {
    //   state.listNames = value
    // },
    // SET_LIST_DATA_COPY(state, value) {
    //   state.listNamesCopy = value
    // },
    // SET_LIST_COUNT(state, value) {
    //   state.listCount = value
    // },

    // UPDATE_LIST_ORDER(state, payload) {
    //   const copy = state.listNames;

    //   if (payload.order == 1) {
    //     copy.sort((a, b) => {
    //       let compare = 0;
    //       if (a[payload.sortKey] > b[payload.sortKey]) {
    //           compare = 1;
    //       } else if (b[payload.sortKey] > a[payload.sortKey]) {
    //           compare = -1;
    //       }
    //       return compare;
    //     });

    //     state.listNames = copy
    //   } else {
    //     copy.sort((a, b) => {
    //       let compare = 0;
    //       if (a[payload.sortKey] < b[payload.sortKey]) {
    //           compare = 1;
    //       } else if (b[payload.sortKey] < a[payload.sortKey]) {
    //           compare = -1;
    //       }
    //       return compare;
    //     });

    //     state.listNames = copy
    //   }


    // },
    // RESET_LIST_VIEW(state, payload) {
    //   const copy = state[payload]

    //   copy.sort((a, b) => {
    //     let compare = 0;
    //     if (a['index'] > b['index']) {
    //         compare = 1;
    //     } else if (b['index'] > a['index']) {
    //         compare = -1;
    //     }
    //     return compare;
    //   });


    //   state.listNames = copy

    // },

  },
  actions: {
    // updateItemData({ commit }, payload) {
    //   commit('SET_ITEM_DATA', payload)
    // },
    updateStoredItems({ commit }, payload) {
      commit('SET_FIREBASE_ITEMS', payload)
    },
    updateUserSettings({ commit }, payload) {
      commit('SET_USER_SETTINGS', payload)
    },
    updateReminders({ commit }, payload) {
      commit('SET_REMINDERS', payload)
    },
    updateUserFiles({ commit }, payload) {
      commit('SET_USER_FILES', payload)
    },
    updateUserContacts({ commit }, payload) {
      commit('SET_USER_CONTACTS', payload)
    },
    // updateListBeingViewed({ commit }, payload) {
    //   commit('SET_LIST_BEING_VIEWED', payload)
    // },
    updateUserProfile({ commit }, payload) {
      commit('SET_USER_PROFILE', payload)
    },
    // createItemDataCopy({ commit }, payload) {
    //   commit('SET_ITEM_DATA_COPY', payload)
    // },
    // updateDisplayedList({ commit }, payload) {
    //   commit('SET_DISP_LIST', payload)
    // },
    // updateDisplayedItem({ commit }, payload) {
    //   commit('SET_DISP_ITEM', payload)
    // },
    updateIsLoading({ commit }, payload) {
      commit('SET_IS_LOADING', payload)
    },
    // updateItemDataFav({ commit }, payload) {
    //   commit('SET_ITEM_DATA_FAV', payload)
    // },
    // updateItemDataPriority({ commit }, payload) {
    //   commit('SET_ITEM_DATA_PRIORITY', payload)
    // },

    // updateListData({ commit }, payload) {
    //   commit('SET_LIST_DATA', payload)
    // },
    // createListDataCopy({ commit }, payload) {
    //   commit('SET_LIST_DATA_COPY', payload)
    // },
    // updateListCount({ commit }, payload) {
    //   commit('SET_LIST_COUNT', payload)
    // },

    // sortListData({ commit }, payload) {
    //   commit('UPDATE_LIST_ORDER', payload)
    // },
    // resetListData({ commit }, payload) {
    //   commit('RESET_LIST_VIEW', payload)
    // },
  },
  modules: {},
  getters: {

    getAllActiveLists (state) {
      // return (convObjectToArray(state.storedItems)).filter(filterArrayActive)
      return (convObjectToListArray(state.storedItems)).filter(filterArrayActive)
    },
    getAllActiveListsItemSearch (state) {
      return (convObjectToArray(state.storedItems)).filter(filterArrayActive)
    },
    // getAllInactiveLists: (state) => (order) => {
    //   return sortArrayByDeleted((convObjectToArray(state.storedItems)).filter(filterArrayInactive), order)
    // },
    getAllInactiveLists (state) {
      return sortArrayByDeleted((convObjectToArray(state.storedItems)).filter(filterArrayInactive), 1)
    },
    getReminders (state) {
      return state.reminders
    },
    getUserSettings (state) {
      return state.userSettings
    },
    // getAllInactiveRecycledLists: (state) => (order, userUID) => {
    //   // console.log(userUID)
    //   // return sortArrayByDeleted(  ((convObjectToArray(state.storedItems)).filter(filterArrayInactive)).filter(filterArrayInactiveByAuthor)  , order, userUID)
    //   return sortArrayByDeleted(  ((convObjectToArray(state.storedItems)).filter(filterArrayByInactiveAndByAuthor))  , order, userUID)
    //   // return sortArrayByDeleted(  ((convObjectToArray(state.storedItems)).filter(filterArrayByInactiveAndByAuthor))  )
    // },
    getClickedList: (state) => (listId) => {
      return state.storedItems[listId]
    },
    getListName: (state) => (listId) => {
      return state.storedItems[listId].name
    },
    getListWriteArray: (state) => (listId) => {
      return state.storedItems[listId].write
    },
    getListAuthor: (state) => (listId) => {
      return state.storedItems[listId].author
    },
    getClickedListItem: (state) => (listId, noteTime) => {
      // console.log(listId, noteTime)
      // console.log(state.storedItems[listId].notes[noteTime])
      return state.storedItems[listId].notes[noteTime]
    },
    getPinnedTime: (state) => (listId) => {
      // console.log(state.userSettings.pinnedLists[listId].time)
      return state.userSettings.pinnedLists[listId].time
    },
  }
})
